import { IconButton, styled } from '@mui/material';
import theme from 'theme/theme';

const DashboardNavbarRoot = styled('div')(() => ({
  backgroundColor: theme.secondary,
  padding: '10px 15px',
  display: 'flex',
  alignItems: 'center',
  gap: 16,

  a: {
    color: theme.white,
    textDecoration: 'none',
  },
}));

const DashboardIconRoot = styled(IconButton)(() => ({
  '&:hover': {
    backgroundColor: theme.hover,
  },
}));

const DashboardMenuRoot = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
  height: 40,
  gap: 10,
}));

const InstructionPopup = styled('div')(() => ({
  backgroundColor: theme.primary,
  width: 600,
  padding: '20px 40px',
  borderRadius: 8,
  textAlign: 'center',
  color: theme.white,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  overflowY: 'auto',
  height: 800,

  '&::-webkit-scrollbar': {
    width: 10,
  },

  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
  },

  '&::-webkit-scrollbar-thumb': {
    background: '#888',
  },

  '@media (max-height: 900px)': {
    height: 600,
  },

  '@media (max-width: 850px)': {
    width: '100%',
    maxWidth: '100%',
    padding: '10px 20px',
  },
}));

const InstructionHeader = styled('h2')(() => ({
  fontSize: 26,

  '@media (max-width: 850px)': {
    fontSize: 18,
  },
}));

const InstructionBox = styled('div')(() => ({
  textAlign: 'left',
  margin: '10px 0',

  p: {
    margin: '10px 0',
    fontSize: 18,
  },

  '@media (max-width: 850px)': {
    p: {
      margin: '5px 0',
      fontSize: 13,
    },
  },
}));

export {
  DashboardIconRoot,
  InstructionHeader,
  InstructionPopup,
  DashboardMenuRoot,
  InstructionBox,
};
export default DashboardNavbarRoot;
