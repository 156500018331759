import { useRoutes } from 'react-router';
import React, { lazy } from 'react';

import { DashboardLayout } from 'layout';
import * as Routes from './path';

const LazyGamePage = lazy(() => import('features/GamePage/GamePage'));

const routes = [
  {
    path: Routes.MAIN_ROUTE,
    element: (
      <DashboardLayout>
        <LazyGamePage />
      </DashboardLayout>
    ),
  },
];

const Router = () => {
  const pages = useRoutes(routes);
  return pages;
};

export default Router;
