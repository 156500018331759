/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import DashboardContent from './DashboardContent/DashboardContent';
import DashboardNavbar from './DashboardNavbar/DashboardNavbar';

const DashboardLayout = ({ children }) => {
  return (
    <div>
      <DashboardNavbar />
      <DashboardContent>{children}</DashboardContent>
    </div>
  );
};

export default DashboardLayout;
