import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import BarChartIcon from '@mui/icons-material/BarChart';
import React, { useEffect, useState } from 'react';

import DashboardNavbarRoot, {
  DashboardIconRoot,
  DashboardMenuRoot,
  InstructionBox,
  InstructionHeader,
  InstructionPopup,
} from './DashboardNavbar.styled';

import useAppStates from 'store/slices/states/useAppStates';

import theme from 'theme/theme';
import { Logo } from 'layout';
import { BaseModal } from 'UI';
import DICTIONARY from 'constants/Dictionary';

const DashboardNavbar = () => {
  const { handleStatisticOpen } = useAppStates();
  const [openInstruction, setOpenInstruction] = useState(false);

  const handleOpen = () => {
    handleStatisticOpen(true);
  };

  const handleOpenInstruction = () => {
    setOpenInstruction((prevState) => !prevState);
  };

  const renderPopup = (
    <BaseModal
      open={openInstruction}
      onClose={handleOpenInstruction}
      sx={{
        width: '600px',
        top: '50%',
        '@media (max-width: 850px)': {
          width: '100%',
          maxWidth: '100%',
          padding: '10px 20px',
        },
      }}
    >
      <InstructionPopup>
        <InstructionBox style={{ width: '100%' }}>
          <p>
            <b>Instructions</b>
          </p>
        </InstructionBox>
        <InstructionBox>
          <p>
            <b>Game rules</b>
          </p>
          <p>
            Select a player for each cell that matches the criteria for the
            intersection of that cell's row and column. You cannot guess the
            same correct player for more than one square. Players may be active
            or inactive but must have previously played at least one Indian
            Premier League game for a given team. For awards and statistics, the
            player does not have to have earned the accomplishment while on that
            team. For career stats they can be accumulated across multiple
            seasons across multiple teams. Players have 10 guesses to fill out
            the grid. Aim for a low rarity
          </p>
        </InstructionBox>
        <InstructionBox>
          <p>
            <b>Rarity Score</b>
          </p>
          <p style={{ margin: 0 }}>
            Your rarity score rewards you for how unique your answers are.
          </p>
          <p style={{ margin: 0 }}>
            Every correct answer is worth up to 100 points. The more rare your
            answers are compared to other players, the higher your score will
            be.
          </p>
          <p style={{ margin: 0 }}>
            If you guess a player that only 10% of other players have guessed,
            you will score 100 – 10% = 90 for that square.
          </p>
          <p style={{ margin: 0 }}>
            Your total score at the end of the game is the sum of all 9 squares.
            Any blank squares score 0
          </p>
          <p>The closest you can get to 900, the better! Good luck.</p>
        </InstructionBox>

        <InstructionBox style={{ width: '100%' }}>
          <p>
            <b>निर्देश</b>
          </p>
        </InstructionBox>
        <InstructionBox>
          <p>
            <b>खेल के नियम</b>
          </p>
          <p style={{ margin: 0 }}>
            प्रत्येक सेल के लिए एक खिलाड़ी चुनें जो उस सेल की पंक्ति और स्तंभ के
            प्रतिच्छेदन के मानदंड से मेल खाता हो।
          </p>
          <p style={{ margin: 0 }}>
            आप एक से अधिक वर्गों के लिए एक ही सही खिलाड़ी का अनुमान नहीं लगा
            सकते।
          </p>
          <p style={{ margin: 0 }}>
            खिलाड़ी सक्रिय या निष्क्रिय हो सकते हैं, लेकिन उन्हें किसी निश्चित
            टीम के लिए कम से कम एक इंडियन प्रीमियर लीग खेल खेलना होगा।
          </p>
          <p style={{ margin: 0 }}>
            पुरस्कार और सांख्यिकी के लिए, खिलाड़ी को उस टीम में रहते हुए उपलब्धि
            अर्जित करने की आवश्यकता नहीं है।
          </p>
          <p style={{ margin: 0 }}>
            करियर के आंकड़ों के लिए उन्हें कई टीमों में कई सीज़न में जमा किया जा
            सकता है। खिलाड़ियों के पास ग्रिड को भरने के लिए 10 अनुमान हैं। कम
            दुर्लभता का लक्ष्य रखें।
          </p>
        </InstructionBox>

        <InstructionBox>
          <p>
            <b>दुर्लभता स्कोर</b>
          </p>
          <p style={{ margin: 0 }}>
            आपका दुर्लभता स्कोर आपको आपके उत्तरों की विशिष्टता के लिए पुरस्कृत
            करता है।
          </p>
          <p style={{ margin: 0 }}>
            प्रत्येक सही उत्तर 100 अंकों तक का होता है। अन्य खिलाड़ियों की तुलना
            में आपके उत्तर जितने दुर्लभ होंगे, आपका स्कोर उतना ही अधिक होगा।
          </p>
          <p style={{ margin: 0 }}>
            यदि आप किसी ऐसे खिलाड़ी का अनुमान लगाते हैं जिसका अनुमान केवल 10%
            अन्य खिलाड़ियों ने लगाया है, तो आप उस वर्ग के लिए 100 - 10% = 90 अंक
            प्राप्त करेंगे।
          </p>
          <p style={{ margin: 0 }}>
            खेल के अंत में आपका कुल स्कोर सभी 9 वर्गों का योग होता है।
          </p>
          <p style={{ margin: 0 }}>किसी भी खाली वर्ग का स्कोर 0 होगा।</p>
          <p style={{ margin: 0 }}>
            आप 900 के जितना करीब पहुंचेंगे, उतना बेहतर होगा! शुभकामनाएँ।
          </p>
          <p></p>
        </InstructionBox>
      </InstructionPopup>
    </BaseModal>
  );

  useEffect(() => {
    const localeStorageToken = localStorage.getItem(DICTIONARY.TOKEN);
    if (!localeStorageToken) {
      setOpenInstruction(true);
    }
  }, []);

  return (
    <DashboardNavbarRoot>
      <Logo />
      <DashboardMenuRoot>
        <DashboardIconRoot>
          <BarChartIcon sx={{ color: theme.white }} onClick={handleOpen} />
        </DashboardIconRoot>

        <DashboardIconRoot>
          <InfoOutlinedIcon
            sx={{ color: theme.blue }}
            onClick={handleOpenInstruction}
          />
        </DashboardIconRoot>
      </DashboardMenuRoot>

      {renderPopup}
    </DashboardNavbarRoot>
  );
};

export default DashboardNavbar;
