import ReactDOM from 'react-dom/client';
import React from 'react';

import App from './App';

import 'normalize.css';
import './assets/css/global.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'simplebar-react/dist/simplebar.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
