import { NavLink } from 'react-router-dom';
import React from 'react';

import logoPng from 'assets/images/Logo.png';
import { Box } from '@mui/material';

const Logo = ({ href = '/' }) => {
  return (
    <NavLink to={href}>
      <Box display="flex" alignItems="center" gap="10px">
        <img src={logoPng} alt="logo" style={{ width: 40, height: 40 }} />
        IPL Grid
      </Box>
    </NavLink>
  );
};

export default Logo;
