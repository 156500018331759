import { IconButton, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import BaseModalInner, { StyledButton } from './BaseModal.styled';

const BaseModal = ({ children, open, onClose, sx }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <BaseModalInner sx={{ ...sx }}>
        <StyledButton>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </StyledButton>

        {children}
      </BaseModalInner>
    </Modal>
  );
};

export default BaseModal;
