import { styled } from '@mui/material';
import theme from 'theme/theme';

const BaseModalInner = styled('div')(() => ({
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  outline: 'none',

  '@media (max-width: 850px)': {
    width: '100%',
    maxWidth: '90%',
  },
}));

const StyledButton = styled('div')(() => ({
  position: 'absolute',
  right: 5,
  top: 5,

  svg: {
    color: theme.white,
  },

  '@media (max-width: 850px)': {
    right: 15,
    top: 15,
  },
}));

export { StyledButton };
export default BaseModalInner;
