import { useSelector, useDispatch } from 'react-redux';

import { handleOpenStatistic } from './slice';

const useAppStates = () => {
  const dispatch = useDispatch();
  const { openStatistic } = useSelector((state) => state.appStates);

  const handleStatisticOpen = (state) => {
    return dispatch(handleOpenStatistic(state));
  };

  return {
    openStatistic,
    handleStatisticOpen,
  };
};

export default useAppStates;
