import React, { Suspense } from 'react';

import AppProvider from 'providers/AppProvider';
import Router from 'routes/router';

const App = () => {
  return (
    <AppProvider>
      <Suspense fallback={<div>Loading...</div>}>
        <Router />
      </Suspense>
    </AppProvider>
  );
};

export default App;
