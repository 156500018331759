import { createSlice } from '@reduxjs/toolkit';

import REDUX_STATES from './actions';

const initialState = {
  openStatistic: false,
};

const statesSlice = createSlice({
  name: REDUX_STATES,
  initialState,
  reducers: {
    handleOpenStatistic(state, action) {
      state.openStatistic = action.payload;
    },
  },
});

export const { handleOpenStatistic } = statesSlice.actions;
export default statesSlice.reducer;
